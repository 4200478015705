<template>
	<div class="container-fluid">
		<div class="row bg-secondary justify-content-center h-100" style="min-height: calc(100vh - 193px);">
			<div class="col-md-11 justify-content-center py-5">
				<div class="card overflow-hidden">
					<div class="card-body bg-light">
						<div class="text-right">
							<img src="@/assets/image/auto-compras-icono.webp" alt="Auto compras" width="100" class="img-fluid">
						</div>
						<div class="p-3" style="margin-top: -29px;">
							<div class="row justify-content-center mb-5">
								<div class="col-xl-6 col-lg-8 col-md-10">
									<div class="d-flex flex-column flex-md-row">
										<div class="mr-2 mb-3">
											<img src="@/assets/image/carita_feliz_blue.webp" alt="Gracias">
										</div>
										<div class="Quicksand text-center mb-3">
											<span class="text-sm" style="font-size: 4rem; line-height: 3.5rem; font-weight: bold;">
												¡Gracias!
											</span>
											<span style="font-size: 2rem; font-weight: 700;">
												Pronto nos comunicaremos contigo.
											</span>
										</div>
									</div>
								</div>
							</div>
							<div class="row justify-content-center mt-3">
								<div class="col-lg-4 text-center mb-3">
									<div class="mb-2" style="font-weight: 500;">
										Se parte de nuestra comunidad y enterate de nuestras futuras promociones
									</div>
									<router-link :to="{name:'register'}" class="btn btn-secondary text-primary" style="font-weight: 700;">
										Crear usuario
									</router-link>
								</div>
								<div class="col-lg-2" v-if="false"></div>
								<div class="col-lg-4 text-center mb-3" v-if="false">
									<div class="mb-2" style="font-weight: 500;">
										Accede gratis a los cupones con descuentos de nuestras marcas amigas
									</div>
									<router-link :to="{name:'beneficios.index'}" class="btn btn-secondary text-primary" style="font-weight: 700;">
										Club de beneficios
									</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	export default {
		mounted(){
			this.$gtag.event('conversion', {'send_to': 'AW-707917286/pfTcCI_4-_0CEObrx9EC'})
		}
	}
</script>
