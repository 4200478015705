<template>
	<div class="p-lg-5" v-if="vehiculos.length>0">
		<slot></slot>
		<Carousel :items-to-show="items" :snapAlign="'start'">
			<Slide v-for="(vehiculo, index) in vehiculosFilter" :key="index">
				<VehiculoCard class="w-100 h-100 carousel__item" :data="vehiculo" />
			</Slide>

			<template #addons>
				<Pagination v-if="!isMobile" />
				<Navigation />
			</template>
		</Carousel>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'

	const VehiculoCard = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "VehiculosCard" */"@/components/vehiculos/Card.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	const Carousel = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CarouselV3" */"vue3-carousel").then(mod => mod.Carousel),
		loadingComponent: {template:`<loading-component />`}
	})

	const Navigation = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CarouselV3" */"vue3-carousel").then(mod => mod.Navigation),
		loadingComponent: {template:`<loading-component />`}
	})

	const Slide = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CarouselV3" */"vue3-carousel").then(mod => mod.Slide),
		loadingComponent: {template:`<loading-component />`}
	})

	const Pagination = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CarouselV3" */"vue3-carousel").then(mod => mod.Pagination),
		loadingComponent: {template:`<loading-component />`}
	})

	export default {
		components: {
			VehiculoCard,
			Carousel,
			Slide,
			Navigation,
			Pagination
		},
		data(){
			return {
				loadingVehiculos: false,
				vehiculos: []
			}
		},
		mounted(){
			this.getVehiculosDisponibles()
		},

		methods: {
			getVehiculosDisponibles(){
				this.loadingVehiculos = true
				this.$store.dispatch('getVehiculosDisponibles',{
					limit: 20,
					offset: ((1*20)-20),
					isNew: 1
				}).then(response => {
					this.vehiculos = response.data || []
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingVehiculos = false
				})
			},
		},

		computed: {
			items(){
				if (this.isMobile) {
					return 1
				}
				return 4
			},
			vehiculosFilter(){
				return this.vehiculos || []
			},
			isMobile() {
				return this.$store.getters.isMobile
			},
		}

	}
</script>