<template>
	<div class="container-fluid bg-white">
		<div class="row justify-content-center align-items-center">
			<div class="col-lg-8 mb-3 mb-lg-5 order-1 order-lg-0">
				<h1 class="text-center mt-lg-5 font-weight-bold display-4">¡PRÉSTAMO POR TU CARRO!</h1>
				<div class="row justify-content-center">
					<div class="col-lg-8">
						<p class="h1 mt-3 mt-lg-5" style="font-weight: lighter;">Utiliza como garantía tu carro, este queda en circulación y ¡lo mejor de todo es que tu eliges la mejor cuota!</p>
					</div>
				</div>
				
				<div class="text-center mt-3 mt-lg-5">
					<button type="button" v-on:click="scrollToElement('scrollToMe', 'focusInput')" class="btn btn-secondary rounded-pill font-weight-bold px-5">
						Solicítalo
					</button>
				</div>
			</div>
			<div :class="{'col-lg-4 mb-3 mb-lg-5 p-0 order-0 order-lg-1':true, 'bg-secondary text-center':isMobile, 'text-right':!isMobile}">
				<img src="@/assets/image/prestamo-carro.webp" class="img-fluid" alt="Préstamo" style="margin-right: ">
			</div>
			<div class="col-lg-10 mb-4 order-2">
				<Cotizador v-model="monto" tipo="vehiculos" />
			</div>
		</div>
	</div>
	<div class="container-fluid bg-secondary p-5">
		<BeneficiosPrestamos />
	</div>
	<div class="container-fluid bg-white">
		<div class="row align-items-center justify-content-center">
			<div class="col-lg-10 mt-5 mb-lg-5">
				<div class="row">
					<div class="col-lg-12 mb-5 mb-lg-3">
						<div class="row">
							<div class="col-lg-6">
								<h2 class="mb-2 mb-lg-4 font-weight-bold">¿Qué documentos necesitamos para tus préstamos?</h2>
							</div>
							<div class="col-lg-6">
								<div class="accordion" id="accordionExample">
									<div class="" id="headingOne">
										<h2 class="mb-0">
											<button class="btn btn-block text-left d-flex justify-content-between align-items-center rounded-0 collapsed" style="border-bottom: 1px solid;" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
												<span class="h3 text-primary">Requisitos</span>
												<img src="@/assets/image/down-arrow.webp" alt="">
											</button>
										</h2>
									</div>

									<div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
										<div class="card-body">
											<div class="pr-md-5">
												<ul>
													<li>Título de propiedad del vehículo</li>
													<li>Tarjeta de circulación</li>
													<li>Licencia de conducir vigente</li>
													<li>DPI vigente</li>
													<li>Recibo de agua, luz o teléfono fijo (mínimo 2)</li>
													<li>Últimos 3 estados de cuenta</li>
													<li>Copia de llave de encendido de el vehículo</li>
												</ul>
												<p>
													<strong>Si el vehículo está a nombre de persona jurídica</strong>
													(todo lo anterior) y adicional a:
												</p>
												<ul>
													<li>Copia de nombramiento del representante legal</li>
													<li>DPI del representante legal</li>
													<li>Copia de asamblea general de accionistas extraordinario
														y totalitario (autorizando a representante legal a poner
													bien como garantía)</li>
													<li>Copia escritura constitución sociedad</li>
													<li>Copia de patentes de comercio y sociedad</li>
													<li>Copia de RTU de la empresa</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-12">
						<div class="row">
							<div class="col-lg-6">
								<h2 class="mb-4 font-weight-bold">¿Porqué es mi mejor opción?</h2>
							</div>
							<div class="col-lg-6">
								<div class="accordion" id="accordionExample">
									<div class="" id="headingTwo">
										<h2 class="mb-0">
											<button class="btn btn-block text-left d-flex justify-content-between align-items-center rounded-0" style="border-bottom: 1px solid;" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
												<span class="h3 text-primary">Beneficios</span>
												<img src="@/assets/image/down-arrow.webp" alt="">
											</button>
										</h2>
									</div>

									<div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
										<div class="card-body">
											<div class="pr-md-5">
												<p>Obtén tu dinero en 24 horas eligiendo la cuota que puedas pagar.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid my-5">
			<div class="row justify-content-center" ref="scrollToMe">
				<div class="col-lg-10">	
					<div class="card overflow-hidden" id="formulario">
						<div class="card-body bg-light">
							<h2 class="card-title font-weight-bold text-center mt-4 mb-4">PRE CALIFICA LLENANDO EL FORMULARIO</h2>						
							<div class="row align-items-end justify-content-center px-md-5">
								<div class="col-lg-3 text-center mb-3">
									<img src="@/assets/image/vehiculo-formulario.webp" class="img-fluid" />
								</div>
								<div class="col-lg-8">
									<PrecalifiqueForm tipo="vehículo" v-model="monto"></PrecalifiqueForm>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid bg-white my-5">
			<div class="row justify-content-center">
				<div class="col-lg-10">
					<h1 class="mt-5 mb-5 text-center font-weight-bold display-4">Te compartimos más información</h1>
					<div class="row align-items-center justify-content-center pb-5">
						<div class="col-lg-5 mb-3">
							<div class="accordion" id="accordionInformacion">
								<div class="" id="headingQueEs">
									<h2 class="mb-0">
										<button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center rounded-0" style="border-bottom: 1px solid;" type="button" data-toggle="collapse" data-target="#queEs" aria-expanded="false" aria-controls="queEs">
											<span class="h3 text-primary">¿Qué es crédito sobre vehiculo?</span>
											<img src="@/assets/image/down-arrow.webp" alt="">
										</button>
									</h2>
								</div>

								<div id="queEs" class="collapse" aria-labelledby="headingQueEs" data-parent="#accordionInformacion">
									<div class="card-body">
										<div class="pr-md-5">
											<p>Préstamo utilizando tu carro como garantía. El carro lo sigues usando.</p>
										</div>
									</div>
								</div>
								<div class="" id="headingFunciona">
									<h2 class="mb-0">
										<button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center rounded-0" style="border-bottom: 1px solid;" type="button" data-toggle="collapse" data-target="#funciona" aria-expanded="false" aria-controls="funciona">
											<span class="h3 text-primary">¿Cómo funciona?</span>
											<img src="@/assets/image/down-arrow.webp" alt="">
										</button>
									</h2>
								</div>

								<div id="funciona" class="collapse" aria-labelledby="headingFunciona" data-parent="#accordionInformacion">
									<div class="card-body">
										<div class="pr-md-5">
											<ol>
												<li>
													Llena nuestro formulario de solicitud
												</li>
												<li>
													Nuestros asesores se comunicarán contigo para darle seguimiento a tu solicitud 
												</li>
												<li>
													Analizaremos tu solicitud
												</li>
												<li>
													Si aplicas y aceptas la propuesta, te damos el préstamo
												</li>
											</ol>
										</div>
									</div>
								</div>
								<div class="" id="headingClubCashin">
									<h2 class="mb-0">
										<button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center rounded-0" style="border-bottom: 1px solid;" type="button" data-toggle="collapse" data-target="#clubCashin" aria-expanded="false" aria-controls="clubCashin">
											<span class="h3 text-primary">¿Qué es Clubcashin.com?</span>
											<img src="@/assets/image/down-arrow.webp" alt="">
										</button>
									</h2>
								</div>

								<div id="clubCashin" class="collapse" aria-labelledby="headingClubCashin" data-parent="#accordionInformacion">
									<div class="card-body">
										<div class="pr-md-5">
											<p>
												Somos la 1era Fintech p2p Lending de Guatemala, hemos otorgado millones de quetzales en préstamos a través de cientos de inversionistas. Impactamos en la vida de los guatemaltecos apoyándolos a cumplir sus sueños.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-7 mb-3 text-center">
							<video class="rounded img-fluid" src="https://clubcashin.com/img/CashIn_Usuario.mp4" controls>
								Tu navegador no soporta estos videos
							</video>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'

	const Cotizador = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Cotizador" */"@/components/Cotizador.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const BeneficiosPrestamos = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "PrestamosBeneficiosPrestamos" */"@/components/prestamos/BeneficiosPrestamos.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	const PrecalifiqueForm = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "PrecalifiqueForm" */"@/components/precalifique/Form.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	export default {
		components: {
			Cotizador,
			BeneficiosPrestamos,

			PrecalifiqueForm
		},
		data(){
			return {
				monto: 1
			}
		},
		mounted(){
			if(this.sc && this.sc!=''){
				document.getElementById(this.sc).scrollIntoView({behavior: "smooth"})
			}
		},
		computed: {
			query(){
				return this.$route.query || {}
			},
			sc(){
				return this.query.sc || ''
			},
			isMobile() {
				return this.$store.getters.isMobile
			},
		},
		watch:{
			sc(val){
				if (val) {
					document.getElementById(val).scrollIntoView({behavior: "smooth"})
				}
			}
		},
	}
</script>