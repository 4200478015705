<template>
	<div class="position-relative">
		<div class="position-absolute select-icon">
            <button type="button" class="btn btn-outline-light btn-sm border-0 text-muted shadow-0 d-flex align-items-center" title="Actualizar" v-on:click="getData()" tabindex="-1" :disabled="loading">
            	<span class="material-icons">refresh</span>
            </button>
        </div>
		<select v-bind="$attrs" :class="[classSelect, {'custom-select border':true}]" :value="modelValue" @change="$emit('update:modelValue', $event.target.value)" style="padding-left: 42px;" :disabled="loading || !hiddeElement">
			<option value="" disabled selected>
                <span v-if="loading">Cargando...</span>
                <span v-else>{{titleDF}}</span>
            </option>
			<option v-for="(data, index) in datos" :value="data.descripcion" :key="index">
				<span v-if="loading">Cargando...</span>
                <span v-else>{{data.descripcion}}</span>
			</option>
		</select>
	</div>
</template>
<script type="text/javascript">
	export default {
		inheritAttrs: false,
		props: ['modelValue', 'title', 'classSelect'],
    	emits: ['update:modelValue'],
		data(){
			return {
				loading: false,
				hiddeElement: true
			}
		},
		mounted(){
			if(this.datos.length<=0){
	            this.getData()
	        }else{
	            if(this.datos.length==1){
	                this.hiddeElement = false
	                this.$emit('update:modelValue', this.datos[0].descripcion)
	            }else{
	                this.hiddeElement = true
	            }
	        }
		},
		methods: {
			getData(){
				this.loading = true
				this.$store.dispatch('vehiculosGetColores').then(res=>{
					if(res.data.length==1){
	                    this.hiddeElement = false
	                    this.$emit('update:modelValue', res.data[0].descripcion)
	                }else{
	                    this.hiddeElement = true
	                }
				}).catch(err => {
					return err
				}).then(() => {
					this.loading = false
				})
			}
		},
		computed: {
	        datos() {
	            return this.$store.getters.colores || []
	        },
	        titleDF(){
	        	return this.title || 'Seleccionar color'
	        }
	    },
	}
</script>