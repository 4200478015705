<template>
	<div class="bg-white pb-5">
		<div class="container-fluid bg-white">
			<div class="row justify-content-center">
				<div class="col-lg-8 mb-2 mb-lg-5 order-1 order-lg-0">
					<h1 class="display-3 mt-lg-5 font-weight-bold ml-lg-5">
						Compra el carro de tus sueños
					</h1>
					<div class="row">
						<div class="col-lg-12">
							<p class="h1 mt-3 ml-lg-5 mt-lg-5" style="font-weight: lighter;">
								Nosotros te damos el dinero, <br> tú eliges el carro
							</p>
						</div>
					</div>
					
					<div class="text-center mt-3 mt-lg-5">
						<button type="button" v-on:click="scrollToElement('scrollToMe', 'focusInput')" class="btn btn-secondary rounded-pill font-weight-bold px-4 py-0 btn-lg">
							Solicítalo
						</button>
					</div>
				</div>
				<div :class="{'col-lg-4 mb-3 mb-lg-5 p-0 order-0 order-lg-1':true, 'text-center':isMobile, 'text-right':!isMobile}">
					<img src="@/assets/image/banner-carro-nuevo.webp" class="img-fluid" alt="Préstamo">
				</div>
			</div>
		</div>

		<div class="container-fluid">
			<div class="py-3">
				<h2 class="mb-4 display-4 font-weight-bold text-center">3 razones para comprar tu carro con nosotros</h2>

				<div class="row justify-content-center">
					<div class="col-lg-10">
						<div class="row justify-content-between">
							<div class="col-lg-3 mb-3">
								<div class="text-center mb-3">
									<img src="@/assets/image/descuento.webp" class="img-fluid">
								</div>
								<h3 class="text-center">Análisis más humano</h3>
							</div>
							<div class="col-lg-3 mb-3">
								<div class="text-center mb-3">
									<img src="@/assets/image/coche.webp" class="img-fluid">
								</div>
								<h3 class="text-center text-lg-left">Elige el carro en la agencia que desees</h3>
							</div>
							<div class="col-lg-3 mb-3">
								<div class="text-center mb-3">
									<img src="@/assets/image/sin-dinero.webp" class="img-fluid">
								</div>
								<h3 class="text-center text-lg-left">Sin multas por pago anticipado</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="container-fluid">
			<div class="row py-5 justify-content-center">
				<div class="col-lg-10 mb-5 mb-lg-3">
					<div class="row">
						<div class="col-lg-6">
							<h2 class="mb-2 mb-lg-4 font-weight-bold">¿Cómo funciona?</h2>
						</div>
						<div class="col-lg-6">
							<div class="accordion" id="accordionExample">
								<div class="" id="headingOne">
									<h2 class="mb-0">
										<button class="btn btn-block text-left d-flex justify-content-between align-items-center rounded-0 collapsed" style="border-bottom: 1px solid;" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
											<span class="h3 mb-0 text-primary">Saber más</span>
											<img src="@/assets/image/down-arrow.webp">
										</button>
									</h2>
								</div>

								<div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
									<div class="card-body">
										<div class="pr-md-5">
											Elige el carro que desees en tu agencia de autos favorita. Si la agencia no está afiliada a Clubcashin.com no hay problema, nos comunicaremos con ellos para afiliarla y te proporcionaremos todos nuestros beneficios en caso cumplas con los requisitos.
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-10 mb-3">
					<div class="row">
						<div class="col-lg-6">
							<h2 class="mb-2 mb-lg-4 font-weight-bold mb-3">¿Porqué es mi mejor opción?</h2>
						</div>
						<div class="col-lg-6">
							<div class="accordion" id="accordionExample">
								<div class="" id="headingTwo">
									<h2 class="mb-0">
										<button class="btn btn-block text-left d-flex justify-content-between align-items-center rounded-0 collapsed" style="border-bottom: 1px solid;" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
											<span class="h3 mb-0 text-primary">Beneficios</span>
											<img src="@/assets/image/down-arrow.webp" alt="">
										</button>
									</h2>
								</div>

								<div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
									<div class="card-body">
										<div class="pr-md-5">
												Con nosotros, evitarás engorrosos procesos de aprobación y no tendrás multas o penalizaciones si deseas realizar pagos anticipados.
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="container-fluid">
			<div class="container-fluid my-5">

				<h2 class="h1 text-center mb-3">Cotiza tu cuota y precalifica</h2>

				<div class="d-flex align-items-center justify-content-center mb-3">
					<div class="mr-2">
						<label v-on:click="encuesta.tipoFormulario = false">
							Ya se que carro quiero
						</label>
					</div>
					<div>
						<div class="custom-control custom-switch mb-3">
							<input type="checkbox" class="custom-control-input" id="tipoformulario" v-model="encuesta.tipoFormulario">
							<label class="custom-control-label" for="tipoformulario"></label>
						</div>
					</div>
					<div class="ml-2">
						<label v-on:click="encuesta.tipoFormulario = true">
							Aún no se que carro quiero
						</label>
					</div>
				</div>

				<div class="row justify-content-center" ref="scrollToMe">
					<div class="col-lg-10">
						<form ref="userForm" v-on:submit.prevent="sendAnswer()" class="needs-validation was-validated" novalidate>
							<div class="card overflow-hidden" id="formulario">
								<div class="card-body py-5 bg-light">
									<h2 v-if="encuesta.tipoFormulario" class="text-center">
										Simula tu cuota
									</h2>

									<div class="row mb-lg-5">
										<div class="col-lg mb-3">
											<div class="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center">
												<label class="flex-fill">Valor del carro</label>
												<div class="ml-lg-2">
													<CurrencyInput id="valor1" v-model="encuesta.monto" type="text" name="valor" placeholder="Ingrese monto" class="form-control novalidate" />
												</div>
											</div>
										</div>

										<div class="col-lg-3 mb-3">
											<div class="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center">
												<label>Plazo (meses):</label>
												<div class="ml-lg-2">
													<input type="number" v-model="encuesta.plazo" :class="{'form-control novalidate border':true, 'w100px':!isMobile}" :min="1" :max="maxPlazo" step="1">
												</div>
											</div>
										</div>

										<div class="col-lg mb-3">
											<div class="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center">
												<label class="flex-fill">Enganche ({{enganchePorc}}%)</label>
												<div class="ml-lg-2">
													<CurrencyInput id="enganche2" v-model="encuesta.enganche" type="text" name="enganche" placeholder="Enganche" class="form-control novalidate" />
												</div>
											</div>
										</div>
									</div>
									
									<div class="row justify-content-center align-items-stretch">
										<div class="col-lg-5 text-center my-auto">
											<Cotizador v-model="encuesta.monto" :montoEnganche="encuesta.enganche" :montoPlazo="encuesta.plazo" tipo="auto-compras-nuevo" @enganche="enganche" @maxPlazo="maxPlazoHandler"></Cotizador>
										</div>
										<div class="col-lg-7">
											<div class="form-row justify-content-center">
												<div class="col-lg-6">
													<div class="form-row">
														<div class="col-lg-12 mb-1">
															<input type="text" id="nombre" name="nombre" v-model="encuesta.nombre" placeholder="Nombre" class="form-control" required>
														</div>
													
														<div class="col-lg-12 mb-1">
															<input type="text" id="apellido" name="apellido" v-model="encuesta.apellido" placeholder="Apellido" class="form-control" required>
														</div>

														<div class="col-lg-12 mb-1">
															<input type="email" id="correo" name="correo" v-model="encuesta.mail" placeholder="Correo" class="form-control" required>
														</div>

														<div class="col-lg-12 mb-1">
															<input type="text" id="telefono" name="telefono" v-model="encuesta.telefono" placeholder="Teléfono" class="form-control" required>
														</div>

														<div class="col-lg-12 mb-1">
															<input type="text" id="nit" name="nit" v-model="encuesta.nit" placeholder="NIT" class="form-control" required>
														</div>

														<div class="col-lg-12 mb-1" v-if="!encuesta.tipoFormulario">
															<input type="text" id="marca" name="marca" v-model="encuesta.marca" placeholder="Marca del vehículo" class="form-control" required>
														</div>

														<div class="col-lg-12 mb-1" v-if="!encuesta.tipoFormulario">
															<input type="text" id="anio" name="anio" v-model="encuesta.anio" placeholder="Año de vehículo" class="form-control" required>
														</div>

														<div class="col-lg-12 mb-1" v-if="!encuesta.tipoFormulario">
															<input type="text" id="linea" name="linea" v-model="encuesta.linea" placeholder="Línea de vehículo" class="form-control" required>
														</div>

														<div class="col-lg-12 mb-1" v-if="encuesta.tipoFormulario">
															<textarea type="text" id="comentario" name="comentario" v-model="encuesta.comentario" placeholder="Tu mensaje/comentario" class="form-control" rows="3"></textarea>
														</div>
													</div>
												</div>
												<div class="col-lg-6" v-if="!encuesta.tipoFormulario">
													<div class="d-flex h-100 w-100">
														<div class="mt-auto w-100">
															<div class="mb-1">
																<ColoresName id="color" name="color" v-model="encuesta.color" required />
															</div>

															<div class="mb-1">
																<label class="mb-0 text-muted text-xs" for="valor">Valor del carro</label>
																<CurrencyInput type="text" id="valor" name="valor" v-model="encuesta.monto" placeholder="Valor del carro" class="form-control" required />
															</div>

															<div class="mb-1">
																<label class="mb-0 text-muted text-xs" for="enganche">Enganche</label>
																<CurrencyInput type="text" id="enganche" name="enganche" v-model="encuesta.enganche" placeholder="Enganche" class="form-control" required />
															</div>

															<div class="mb-1">
																<div class="d-flex justify-content-between w-100 py-2">
																	<span class="font-weight-bold">Monto a solicitar: </span>	
																	<span>
																		{{$filters.currency(montoRequerir)}}
																	</span>
																</div>
															</div>

															<div class="mb-1">
																<textarea id="comentario" type="text" name="comentario" v-model="encuesta.comentario" placeholder="Tu mensaje/comentario" class="form-control" rows="3"></textarea>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-lg-12 mt-3 text-right text-md-left">
											<div :class="{'d-flex h-100':true, 'justify-content-center':encuesta.tipoFormulario}">
												<div :class="{'mt-auto ml-auto':!encuesta.tipoFormulario}">
													<button type="submit" class="btn btn-secondary rounded-pill font-weight-bold text-primary" :disabled="loadingVehiculos || !validateForm">
														<BtnLoading v-if="loadingVehiculos" />
														<span v-else>
															Precalificar
														</span>
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>	
						</form>
					</div>
				</div>
			</div>
		</div>

		<div class="container-fluid" v-if="false">
			<VehiculosNewSugerencias>
				<h2 class="h1 text-center font-weight-bold">Carros que te podrían interesar</h2>
			</VehiculosNewSugerencias>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'
	
	import VehiculosNewSugerencias from '@/components/vehiculos/Sugerencias.vue'
	import ColoresName from '@/components/catalogos/ColoresName.vue'

	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const CurrencyInput = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Currency" */"@/components/Currency.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const Cotizador = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Cotizador" */"@/components/Cotizador.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	export default {
		components: {
			BtnLoading,
			CurrencyInput,
			Cotizador,
			VehiculosNewSugerencias,
			ColoresName
		},
		data(){
			return {
				encuesta: {
					nombre: '',
					apellido: '',
					mail: '',
					telefono: '',
					nit: '',
					marca: '',
					anio: '',
					linea: '',
					tipo: '',
					monto: 1,
					enganche: 0,
					comentario: '',
					plazo: 0,
					color: '',
					tipoFormulario: false
				},
				maxPlazo: 0,
				loadingVehiculos: false,
				validateForm: false,
			}
		},
		mounted(){
			this.setDefault()
			if(this.sc && this.sc!=''){
				document.getElementById(this.sc).scrollIntoView({behavior: "smooth"})
			}
		},
		methods: {
			sendAnswer(){
				this.checkValidity()
				if (!this.validateForm) {
					return
				}
				this.loadingVehiculos = true
				var encuesta = this.encuesta
				encuesta.monto = this.montoRequerir

				if(this.encuesta.tipoFormulario){
					this.encuesta.monto = 1
					this.encuesta.plazo = ''
					this.encuesta.enganche = 0
					this.encuesta.marca = ''
					this.encuesta.anio = ''
					this.encuesta.linea = ''
					this.encuesta.color = ''
					//this.encuesta.comentario = ''
				}

				this.$store.dispatch('addPrecalificacion', {
					encuesta: encuesta,
					usuario: this.currentUser,
					nombretipo: "auto compras nuevos",
					tipo: 5
				}).then(response => {
					this.encuesta.nombre = ''
					this.encuesta.apellido = ''
					this.encuesta.mail = ''
					this.encuesta.telefono = ''
					this.encuesta.nit = ''
					this.encuesta.marca = ''
					this.encuesta.anio = ''
					this.encuesta.linea = ''
					this.encuesta.tipo = ''
					this.encuesta.monto = 1
					this.encuesta.comentario = ''
					this.encuesta.enganche = ''
					this.encuesta.color = ''
					this.encuesta.tipoFormulario = false
					
					this.$router.push({name: 'auto-compras.gracias'})
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingVehiculos = false
				})
			},
			enganche(val){
				this.encuesta.enganche = val
			},

			maxPlazoHandler(val){
				this.encuesta.plazo = val
				this.maxPlazo = val
			},

			checkValidity(){
				if (!this.$refs.userForm){
					this.validateForm = false
				}else{
					this.validateForm = this.$refs.userForm.checkValidity()
				}
			},
			setDefault(){
				this.encuesta.nombre = this.currentUser.nombre
				this.encuesta.apellido = this.currentUser.apellido
				this.encuesta.mail = this.currentUser.mail
				this.encuesta.telefono = this.currentUser.telefono

				this.encuesta.monto = this.query.monto || 1
				this.encuesta.enganche = this.query.enganche || 0
				this.encuesta.marca = this.query.marca || ''
				this.encuesta.anio = this.query.anio || ''
				this.encuesta.linea = this.query.modelo || ''
				this.encuesta.tipo = this.query.tipo || ''
				this.encuesta.color = this.query.color || ''
				this.encuesta.tipoFormulario = false

				/*if (this.query.enganche && this.query.enganche>0) {
					this.encuesta.comentario = `Enganche: ${this.query.enganche}`
				}*/
			},
		},
		computed: {
			currentUser(){
				return this.$store.getters.currentUser
			},
			query(){
				return this.$route.query || {}
			},
			sc(){
				return this.query.sc || ''
			},
			montoRequerir(){
				return (this.encuesta.monto || 0) - (this.encuesta.enganche || 0)
			},
			isMobile() {
				return this.$store.getters.isMobile
			},
			enganchePorc(){
				return (((this.encuesta.enganche) / (this.encuesta.monto || 1)*100)).toFixed(2)
			},
		},
		watch:{
			'currentUser':{
				handler(val){
					this.setDefault()
					return val
				},
				deep: true
			},
			'encuesta':{
				handler(val){
					this.checkValidity()
					return val
				},
				deep: true
			},
			sc(val){
				if (val) {
					document.getElementById(val).scrollIntoView({behavior: "smooth"})
				}
			}
		},
	}
</script>